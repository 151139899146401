import React from 'react';
import './App.css';
import FaviconCrawler from './components/FaviconCrawler';

function App() {
  return (
    <div className="App">
      <FaviconCrawler />
    </div>
  );
}

export default App;
