import React, { useState } from 'react';

const FaviconCrawler = () => {
  const [url, setUrl] = useState('');
  const [favicon, setFavicon] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        
        
        const response = await fetch("https://" + url, {
            method: 'GET', 
            timeout: 5000,
            headers: {
              Accept: '*/*',
              'Accept-Encoding': 'gzip, deflate, br',
              'user-agent': 'Mozilla/5.0 (iPad; CPU OS 16_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Mobile/15E148 Safari/604.1'
            }
          });

      const html = await response.text();
      const faviconUrl = getFaviconUrl(html, url);
      if (faviconUrl) {
        setFavicon(faviconUrl);
      } else {
        throw new Error('Favicon not found on the website');
      }
    } catch (error) {
      console.error('Error fetching favicon:', error);
    }
  };

  const cheerio = require('cheerio');

  const getFaviconUrl = (html, baseUrl) => {
    const $ = cheerio.load(html);
    const faviconLink = $('link[rel="icon"], link[rel="shortcut icon"]').first();
    if (!baseUrl.includes("https") | !baseUrl.includes("http")){
        baseUrl = "https://"+baseUrl+"/"
    }
    let faviconUrl = faviconLink.attr('href').replace("./","");
    faviconUrl = baseUrl+faviconUrl
    return faviconUrl || null;
};
  

  return (
    <div>
      <h1>Favicon Crawler</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter website URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button type="submit">Get Favicon</button>
      </form>
      {favicon && (
        <div>
          <h2>Favicon:</h2>
          <img src={favicon} alt="Favicon" style={{ maxWidth: '100px', maxHeight: '100px' }} />
        </div>
      )}
    </div>
  );
};

export default FaviconCrawler;
